/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "~@angular/material/theming";
@import "assets/scss/utilities";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$primary-palette: (
	// Primary Light
	50: #50ca72,
	100: #50ca72,
	200: #50ca72,
	300: #50ca72,
	400: #50ca72,
	// Primary Main
	500: #009845,
	600: #009845,
	700: #009845,
	800: #009845,
	// Primary Dark
	900: #006819,
	contrast: (
		// On Primary
		50: #ffffff,
		100: #ffffff,
		200: #ffffff,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff
	)
);

$accent-palette: (
	// Secondary Light
	50: #e5ff70,
	100: #e5ff70,
	200: #e5ff70,
	300: #e5ff70,
	400: #e5ff70,
	// Secondary Main
	500: #b0d23d,
	600: #b0d23d,
	700: #b0d23d,
	800: #b0d23d,
	// Secondary Dark
	900: #7da100,
	contrast: (
		// On Secondary
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000
	)
);

$error-palette: (
	// Error Light
	50: #e94948,
	100: #e94948,
	200: #e94948,
	300: #e94948,
	400: #e94948,
	// Error Main
	500: #b00020,
	600: #b00020,
	700: #b00020,
	800: #b00020,
	// Error Dark
	900: #790000,
	contrast: (
		// On Error
		50: #ffffff,
		100: #ffffff,
		200: #ffffff,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff
	)
);

$primary: mat.define-palette($primary-palette, 500, 100, 900);
$accent: mat.define-palette($accent-palette, 500, 100, 900);
$error: mat.define-palette($error-palette, 500, 100, 900);

$light-theme: mat.define-light-theme(
	(
		color: (
			primary: $primary,
			accent: $accent,
			warn: $error,
		),
	)
);

$nunito-font: mat-typography-config(
	$font-family: "Nunito",
);

@include mat-core($nunito-font);
@include mat.all-component-themes($light-theme);

html,
body {
	height: 100%;
}
body {
	margin: 0;
}

h1 {
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 96px !important;
	line-height: 112px !important;
	letter-spacing: 112px;
	color: #000000;
}
h2 {
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 60px !important;
	line-height: 71px !important;
	letter-spacing: -0.5px;
	color: #000000;
}
h3 {
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 48px !important;
	line-height: 57px !important;
	color: #000000;
}
h4 {
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 34px !important;
	line-height: 40px !important;
	letter-spacing: 0.25px;
	color: #000000;
}
h5 {
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 24px !important;
	line-height: 32px !important;
	color: #000000;
}
h6 {
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 20px !important;
	line-height: 28px !important;
	letter-spacing: 0.15px;
	color: #000000;
}
.mat-tooltip {
	background-color: #000000;
}
.mat-form-field-appearance-outline {
	.mat-form-field-flex {
		padding-left: 16px !important;
		padding-right: 12px !important;
	}
}

// UI Classes
.subtitle-1 {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #000000;
}

.subtitle-2 {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #212121;
}
.body-1 {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.44px;
	color: #212121;
}
.body-2 {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #212121;
}
.button {
	font-style: normal;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 16px !important;
	letter-spacing: 1.25px;
	text-transform: uppercase;
}
.mat-tooltip,
.caption {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: #212121;
}
.overline {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #212121;
}
.badge {
	font-family: "Lato", sans-serif !important;
	font-style: normal;
	font-weight: 900;
	font-size: 8px;
	line-height: 10px;
	display: flex;
	align-items: center;
	letter-spacing: 0.25px;
	color: #212121;
}
.chat-text {
	font-family: "Kanit", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.15px;
	color: #212121;
}

// Extra Colours
.mirlin-purple {
	color: #fff;
	background: #220011;
}
.text-dark-2 {
	color: rgba(33, 33, 33, 0.6);
}

// Global Classes
.spacer {
	flex: 1 1 auto;
}
.flex-column {
	display: flex;
	flex-direction: column;
}
.flex-row {
	display: flex;
	flex-direction: row;
}
.divider {
	height: 1px;
	align-self: stretch;
	flex-grow: 0;
	background-color: rgba(0, 0, 0, 0.08);
	margin-top: 8px;
	margin-bottom: 8px;
}
.page-toolbar {
	top: 0;
	position: sticky;
	z-index: 1000;
	padding-left: 24px !important;
	padding-right: 24px !important;
	height: 84px;
	background-color: white;
	place-content: space-between;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
	& .start-section,
	.end-section {
		display: flex;
		flex-direction: row;
		gap: 10px;
	}
}
.paginator-styles {
	padding-left: 16px;
	padding-right: 16px;
	.mat-paginator-icon {
		width: 24px;
	}
}
.thin-scrollbar {
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: lightgray;
	}
}
