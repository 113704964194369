$padding-sizes: (
  "sm": 6px,
  "md": 12px,
  "lg": 18px,
  "xl": 24px,
);

@each $pname, $psize in $padding-sizes {
  .padding-#{$pname} {
    padding: #{$psize};
  }
}

$text-sizes: (
  "sm": 12px,
  "md": 16px,
  "lg": 22px,
  "xl": 28px,
);

@each $tname, $tsize in $text-sizes {
  .text-#{$tname} {
    font-size: #{$tsize};
  }
}

$text-colors: (
  "grey": #9ca3af,
);

@each $tname, $tcolor in $text-colors {
  .text-#{$tname} {
    color: #{$tcolor};
  }
}
